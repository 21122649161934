import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from '@reach/router';

import { PlanRouter } from '../components/router';
import { TareaLayout } from '../layouts';
import { api } from '../services';
import { useRedirectTask } from '../hooks';
import { setUnlockedSteps } from '../store/actions/step';
import { tareasSinDetalle } from '../constants';

const TareaPage = props => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);
  const { fetchTaskData, updateMyProgress } = api();
  const dispatch = useDispatch();
  const stepStore = useSelector(({ step }) => step);
  const progress = useSelector(({ step }) => step.unlockedSteps);

  // check if task is available and redirect to last task
  useRedirectTask(props.id);

  const addTask = useCallback(
    (stepId, numberTask) => {
      // Array of task unlocks
      const unlockedTasks = stepStore.unlockedSteps
        .map(step => step.tasks)
        .reduce((obj, val) => [...obj, ...val], []);
      // Check if step is unlock
      const isUnlocked = unlockedTasks.includes(parseInt(numberTask, 10));
      if (!isUnlocked) {
        const lastTask = Math.max(...unlockedTasks);
        if (numberTask && lastTask + 1 === numberTask)
          dispatch(
            setUnlockedSteps(parseInt(stepId, 10), parseInt(numberTask, 10))
          );
      }
    },
    [dispatch, stepStore.unlockedSteps]
  );

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchTaskData(props.id, currentLang);

        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, props.id, fetchTaskData]);

  // para agregar la tarea desbloqueada
  useEffect(() => {
    if (!loading && data && tareasSinDetalle.includes(parseInt(props.id, 10))) {
      // console.log('Esta tarea debería desbloquear la siguiente');
      addTask(data.stepId, data.taskNumber);
      // Agrega la siguiente tarea como desbloqueada
      if (parseInt(props.id, 10) <= 41)
        addTask(data.stepId, parseInt(props.id, 10) + 1);
    }
  }, [data, loading, addTask, props.id]);

  useEffect(() => {
    updateMyProgress(currentToken, progress);
  }, [progress]);
  return (
    <Router>
      <PlanRouter
        path="/tarea/:id"
        component={TareaLayout}
        id={props.id}
        data={data}
        loading={loading}
        error={error}
      />
    </Router>
  );
};

export default TareaPage;
